import { ERoutes } from '~/types/routes'

export enum EServiceNames {
  Orders = 'Orders',
  PreRetailerSession = 'Start a shared session',
  RetailerSession = 'Shared session',
  SavedSearch = 'Saved searches',
  RecentlyViewed = 'Recently viewed',
  Shortlist = 'Shortlist',
  Valuations = 'Car valuations',
  BackOrders = 'Stock notifications',
  Enquiries = 'Enquiries',
  TestDrives = 'Test drives',
  Settings = 'Settings'
}

export interface IService {
  label: EServiceNames
  icon: string
  route: ERoutes
  loginRequired: boolean
}

export type IServiceCount = {
  [key in EServiceNames]: number
}
